<template>
  <v-container>
    <v-row>
      <v-col>
        <h5 class="grey-text">
          <router-link class="grey-text" :to="{ name: 'Home' }"
            >Home</router-link
          >
          >
          <router-link :to="{ name: 'Blog' }">Blog</router-link>
          > 1st Annual "T3" Spikeball Tournament
        </h5>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12">
        <v-img class="dark align-center" :src="TThreeFirstAnnual"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="mt-2">1st Annual "T3" Spikeball Tournament</h3>
        <h5 class="grey-text">November 13th, 2021 - Stone Osborne</h5>
        <p class="mt-2">
          The 1st Annual "T3" Spikeball Tournament boasted a
          <strong>14 team</strong> double elimaination open spikeball tournament
          that included pool play for the participants. The open division was
          followed by an abbreviated "youth" single elimaination tournament. The
          2021 open tournament was our most competitive the date!
          Congratulations to both winning teams and the recipent of the Herbert
          Hustle award.
        </p>
      </v-col>
      <v-col cols="6" sm="6">
        <v-img
          class="dark align-center"
          src="/images/T3GroupTall2021.JPG"
          contain
          max-height="500"
        />
      </v-col>
      <v-col cols="6" sm="6">
        <v-img
          class="dark align-center"
          src="/images/T3Cans.JPG"
          contain
          max-height="500"
        />
      </v-col>
      <v-col>
        <p>
          We surpassed our goal of 100 canned goods with 129 total cans. Thank
          you to everyone who came out to play and donated to those in need this
          Thanksgiving.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>T3 Awards (2021)</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-img
          class="dark align-center"
          src="/images/T3Open2021.JPG"
          contain
          max-height="700"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <h3>
          Open 2021 Champs
          <br />
          "The Rocks"
        </h3>
        <p>Charlie Gallahue and Weston Judd</p>

        <p>
          "The Rocks" had a <strong>clean sweep</strong> in the Open Division
          going undeafeated during pool and bracket play! Rivaling that of the
          Steel Camels historic run in 2020. The combination of high powered
          serves and impressive display of team chemistry allowed "The Rocks" to
          steam roll this years competition.

          <br />
          <br />
          -When asked what was the key to success, they answered: "The Rocks are
          Hot".
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-img
          class="dark align-center"
          src="/images/T3Youth2021.JPG"
          contain
          max-height="700"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <h3>
          Youth 2021 Champs
          <br />
          "Face Breakers"
        </h3>
        <p>Gray Davis and Griffin Brunstetter</p>

        <p>
          "Face Breakers" not only took home the
          <strong> Gold in the Youth Division </strong> but also had a great run
          in Open Division as well. Falling just short of a trip to the
          championship game after dropping two games to eventual
          <strong> Open Champions "The Rocks" </strong>.

          <br />
          <br />
          -When asked what was the key to success, they answered: "I saw the
          ball coming at me so I hit it right back where it came from".
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-img
          class="dark align-center"
          src="/images/T3Herbert2021.JPG"
          contain
          max-height="700"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <h3>
          Herbert Hustle Award
          <br />
          Michael McGuire
        </h3>

        <h6>
          <router-link :to="{ name: 'LegendaryAlert' }"
            >(Pictured in white)</router-link
          >
        </h6>
        <br />

        <p>
          Michael McGuire is the first ever recipient of this prestigious award.
          Michael displayed uncanny hustle and effort throughout the tournament.
          It was a joy watching Micheal play in this year's tournament.

          <br />
          <br />
          -When asked how does it feel to win this award Michael answered "It
          was very painful but well worth it".
        </p>
      </v-col>
    </v-row>

    <v-divider class="mt-6"></v-divider>

    <v-row class="mt-1">
      <v-col cols="12">
        <h3>Interested in Competing in the T3?</h3>

        <p>
          Send us an email with your team name, age group, and roster. (3
          players max)
        </p>
        <ContactButton title="Sign Up" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import TThreeFirstAnnual from '@/assets/images/T31stAnnual.svg'
// import TThreeGroupTall from '@/assets/images/T3GroupTall2021.JPG'

export default {
  components: { ContactButton },
  data() {
    return {
      TThreeFirstAnnual: TThreeFirstAnnual,
    }
  },
}
</script>

<style>
.v-application a {
  color: grey;
}
</style>
